module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Energi Nexus","short_name":"Nexus","description":"Welcome to Nexus - Your command center for all things at Energi.","lang":"en","start_url":"/","background_color":"#263238","theme_color":"#00e676","display":"minimal-ui","icon":"src/images/energi.png","cache_busting_mode":"none","localize":[{"start_url":"/de/","lang":"de"},{"start_url":"/nl/","lang":"nl"}],"include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BNM0HBS1KH"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
