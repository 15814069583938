// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alerts-jsx": () => import("./../src/pages/alerts.jsx" /* webpackChunkName: "component---src-pages-alerts-jsx" */),
  "component---src-pages-coin-migration-jsx": () => import("./../src/pages/coin-migration.jsx" /* webpackChunkName: "component---src-pages-coin-migration-jsx" */),
  "component---src-pages-ebi-jsx": () => import("./../src/pages/ebi.jsx" /* webpackChunkName: "component---src-pages-ebi-jsx" */),
  "component---src-pages-energi-wallet-jsx": () => import("./../src/pages/energi-wallet.jsx" /* webpackChunkName: "component---src-pages-energi-wallet-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-masternodes-announce-jsx": () => import("./../src/pages/masternodes/announce.jsx" /* webpackChunkName: "component---src-pages-masternodes-announce-jsx" */),
  "component---src-pages-masternodes-collateral-deposit-jsx": () => import("./../src/pages/masternodes/collateral/deposit.jsx" /* webpackChunkName: "component---src-pages-masternodes-collateral-deposit-jsx" */),
  "component---src-pages-masternodes-collateral-jsx": () => import("./../src/pages/masternodes/collateral.jsx" /* webpackChunkName: "component---src-pages-masternodes-collateral-jsx" */),
  "component---src-pages-masternodes-collateral-withdraw-jsx": () => import("./../src/pages/masternodes/collateral/withdraw.jsx" /* webpackChunkName: "component---src-pages-masternodes-collateral-withdraw-jsx" */),
  "component---src-pages-masternodes-denounce-jsx": () => import("./../src/pages/masternodes/denounce.jsx" /* webpackChunkName: "component---src-pages-masternodes-denounce-jsx" */),
  "component---src-pages-masternodes-jsx": () => import("./../src/pages/masternodes.jsx" /* webpackChunkName: "component---src-pages-masternodes-jsx" */),
  "component---src-pages-masternodes-listing-jsx": () => import("./../src/pages/masternodes/listing.jsx" /* webpackChunkName: "component---src-pages-masternodes-listing-jsx" */),
  "component---src-pages-requirements-jsx": () => import("./../src/pages/requirements.jsx" /* webpackChunkName: "component---src-pages-requirements-jsx" */),
  "component---src-pages-reward-calculator-jsx": () => import("./../src/pages/reward-calculator.jsx" /* webpackChunkName: "component---src-pages-reward-calculator-jsx" */),
  "component---src-pages-staking-analytics-jsx": () => import("./../src/pages/staking/analytics.jsx" /* webpackChunkName: "component---src-pages-staking-analytics-jsx" */),
  "component---src-pages-treasury-budget-jsx": () => import("./../src/pages/treasury-budget.jsx" /* webpackChunkName: "component---src-pages-treasury-budget-jsx" */),
  "component---src-pages-treasury-jsx": () => import("./../src/pages/treasury.jsx" /* webpackChunkName: "component---src-pages-treasury-jsx" */)
}

