/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'purecss/build/pure-min.css';
import 'purecss/build/grids-responsive-min.css';

// adding rmwc styles: currently the only way to make sure rrmwc styles end up in the build file:
// TODO: make "import '@material/radio/dist/mdc.radio.css';" work instead of entire styles as defined below"
import './src/assets/css/rmwc-styles.scss';
import './src/assets/css/global.scss';

// TODO: importing every single stylesheet does not end up in the build file
// import '@material/radio/dist/mdc.radio.css';
// import '@material/form-field/dist/mdc.form-field.css';
// import '@material/textfield/dist/mdc.textfield.css';
// import '@material/floating-label/dist/mdc.floating-label.css';
// import '@material/notched-outline/dist/mdc.notched-outline.css';
// import '@material/line-ripple/dist/mdc.line-ripple.css';

// Need to suppress Brave from messaging "Crypto Wallet":
import {suppressMsg} from './src/lib/suppress-brave-cryptowallet';

suppressMsg(); // no Brave messages, yeah!
